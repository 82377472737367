//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  email,
  required,
  minLength,
} from "vuelidate/lib/validators";

export default {
  name: "SignInPage",
  data() {
    return {
      waiting: false,
      email: "",
      password: "",
      isPwd: false,
      errorMessage: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
  computed: {
    emailErrorMessage() {
      if (!this.$v.email.required) {
        return "Preencha um email";
      } else if (!this.$v.email.email) {
        return "Por favor insira um email válido";
      }
      return "Oops algo errado aconteceu!";
    },
    passwordErrorMessage() {
      if (!this.$v.email.required) {
        return "Informe sua senha";
      }
      if (!this.$v.password.minLength) {
        return "Mínimo de 8 dígitos";
      }
      return "Oops algo errado aconteceu!";
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        if (this.waiting) {
          this.$q.notify({
            message: "Por favor, aguarde.",
            position: 'top-right',
          });
          return;
        }
        this.waiting = true;
        await this.$store
          .dispatch("users/retrieveToken", {
            credentials: {
              email: this.email,
              password: this.password,
            },
          })
          .then((response) => {
            if (response.data.user.confirmToken !== null) {
              this.$q.notify({
                message: "É necessário confirmar o email",
                color: "Black",
                position: 'top-right',
              });
            } else {
              this.$router.push({ name: "Profile" });
            }
            this.email = "";
            this.password = "";
            this.waiting = false;
          })
          .catch((error) => {
            if (error.message === "Request failed with status code 400") {
              this.$q.notify({
                message: "Não encontramos uma conta com esse email",
                color: "Black",
                position: 'top-right',
              });
            }
            if (error.message === "Request failed with status code 401") {
              this.$q.notify({
                message: "Email ou senha inválidos",
                color: "Black",
                position: 'top-right',
              });
            }
            if (error.message === "timeout of 5000ms exceeded") {
              this.$q.notify({
                message: "Houve um Problema, tente novamente",
                color: "Black",
                position: 'top-right',
              });
            }
            this.waiting = false;
          });
      } else {
        this.$q.notify({
          message: "Por favor, preencha os campos corretamente.",
          position: 'top-right',
        });
      }
    },
  },
};
